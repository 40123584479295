import { render, staticRenderFns } from "./PortalGrid.vue?vue&type=template&id=97bcf622&scoped=true&"
import script from "./PortalGrid.vue?vue&type=script&lang=js&"
export * from "./PortalGrid.vue?vue&type=script&lang=js&"
import style0 from "./PortalGrid.vue?vue&type=style&index=0&id=97bcf622&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97bcf622",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace});
