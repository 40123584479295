<template>
  <ItemDetails
    :value="value"
    :history="history"
    :overview="overview"
    @edit="handleEdit"
    @input="handleInput"
    @delete="handleDelete"
  >
    <template #title>Portal Details</template>
  </ItemDetails>
</template>

<script>
import { portal } from "@/api/factory.js";
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "PortalDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      required: true,
    },

    portal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      history: [],
    };
  },

  computed: {
    overview() {
      return this.columns.map((column) => ({
        id: column.id,
        label: column.label,
        value: this.portal[column.name],
      }));
    },
  },

  watch: {
    portal: {
      immediate: true,
      deep: true,
      handler() {
        if (this.portal.id) {
          //this.getPortalHistory();
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleEdit() {
      this.$emit("input", false);
      this.$emit("edit", this.portal.id);
    },

    handleDelete() {
      this.$emit("input", false);
      this.$emit("delete", this.portal.id);
    },

    /* api functions */

    async getPortalHistory() {
      this.isLoading = true;

      const { error, payload } = await portal.getPortalHistory(this.portal.id);

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.history = payload;
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped></style>
