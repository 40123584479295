import { render, staticRenderFns } from "./ConfirmDeletePortal.vue?vue&type=template&id=47d34d31&scoped=true&"
import script from "./ConfirmDeletePortal.vue?vue&type=script&lang=js&"
export * from "./ConfirmDeletePortal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d34d31",
  null
  
)

export default component.exports